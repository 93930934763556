import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Head from 'next/head';

import { ScrollContainer } from 'components/ui/AppLayout';

import Awards from './Awards';
import Brands from './Brands';
import Capabilities from './Capabilities';
import Careers from './Careers';
import ClientsIntro from './ClientsIntro';
import Culture from './Culture';
import Footer from './Footer';
import Hero from './Hero';
import People from './People';
import Reel from './Reel';

import styles from './Studio.scss';

class Studio extends React.Component {
    static getInitialProps = ({ store }) => {
        const state = store.getState();
        const { contentTypeId, reels } = state.pages['/studio'];
        return {
            contentTypeId,
            reelIndex: Math.floor(Math.random() * reels.length),
        };
    };

    static propTypes = {
        // Global pageProps from App.js getInitialProps
        studio: PropTypes.shape({
            awardCarousel: PropTypes.array,
            capabilities: PropTypes.array,
            careerDescription: PropTypes.string,
            careerHeadline: PropTypes.string,
            clientHeadline: PropTypes.string,
            clients: PropTypes.array,
            clientSubheadline: PropTypes.string,
            ctaHeadline: PropTypes.string,
            ctaLink: PropTypes.string,
            ctaSubheadline: PropTypes.string,
            cultureCarousel: PropTypes.array,
            description: PropTypes.string,
            headline: PropTypes.string,
            linkToJobOpportunities: PropTypes.string,
            people: PropTypes.array,
            reels: PropTypes.array,
            reelVimeoUrl: PropTypes.string,
            secondaryHeadline: PropTypes.string,
            subheadline: PropTypes.string,
        }).isRequired,
        // Component-specific props from getInitialProps
        contentTypeId: PropTypes.string.isRequired,
        reelIndex: PropTypes.number.isRequired,
        // Props passed directly from App.js render()
        fontsLoaded: PropTypes.bool.isRequired,
    };

    render() {
        const { studio, reelIndex, fontsLoaded } = this.props;

        return (
            <ScrollContainer className={styles.studioContainer}>
                <Head>
                    <title>Studio | HAUS</title>
                </Head>
                <div className={styles.studio}>
                    <Hero
                        headline={studio.headline}
                        subheadline={studio.subheadline}
                        fontsLoaded={fontsLoaded}
                    />
                    <Reel
                        reels={studio.reels}
                        reelIndex={reelIndex}
                        vimeoReel={studio.reelVimeoUrl}
                    />
                    <Capabilities
                        title={studio.secondaryHeadline}
                        body={studio.description}
                        skillsets={studio.capabilities}
                    />

                    <People
                        collaborators={studio.people}
                        scrollTarget={styles.studioContainer}
                    />

                    <ClientsIntro
                        tagline={studio.clientSubheadline}
                        title={studio.clientHeadline}
                    />
                    <Brands list={studio.clients} />
                    <Awards list={studio.awardCarousel} />
                    <Careers
                        title={studio.careerHeadline}
                        body={studio.careerDescription}
                        link={studio.linkToJobOpportunities}
                    />
                    <Culture content={studio.cultureCarousel} />
                </div>
                <Footer
                    tagline={studio.ctaSubheadline}
                    callToAction={studio.ctaHeadline}
                    link={studio.ctaLink}
                />
            </ScrollContainer>
        );
    }
}

export default connect()(Studio);
